import { Link } from 'gatsby';
import React from 'react';

const PostCategory = ({ slug, text }) => {
  return (
    <span className="mx-1" key={slug}>
      <Link
        to={slug || null}
        className="font-medium uppercase text-sm text-chelsea-gem hover:underline focus:underline"
      >
        {text}
      </Link>
    </span>
  );
}

export default PostCategory;
