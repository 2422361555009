import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { EN_CODE } from '../common/Constants';
import CategoryTemplateDetails from '../components/CategoryTemplateDetails/CategoryTemplateDetails';
import Layout from '../components/Layout/Layout';
import Sidebar from '../components/Sidebar/Sidebar';

const CategoryTemplate = (props) => {
  const { title } = props.data.site.siteMetadata;
  const { category } = props.pageContext;
  const lang = props.path.slice(1, 3);
  const { location } = props;
  const siteTitle =
    lang === EN_CODE ? title : 'Réflexions par Thomas Derflinger';

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet title={`${category} - ${title}`}>
      </Helmet>
      <Sidebar {...props} lang={lang} />
      <CategoryTemplateDetails {...props} lang={lang} />
    </Layout>
  );
}

export default CategoryTemplate;

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
        author {
          name
          nameFr
          twitter
          github
          rss
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          category: { eq: $category }
          layout: { eq: "post" }
          draft: { ne: true }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
            lang
            attachments {
              childImageSharp {
                gatsbyImageData(
                  width: 960
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
