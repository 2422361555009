import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import PostCategory from '../PostCategory/PostCategory';
import PostDate from '../PostDate/PostDate';

const PostTitle = ({ title, slug }) => {
  return (
    <h2 className="mt-0">
      <Link
        className="text-black hover:text-black focus:text-black hover:underline focus:underline"
        to={slug}
      >
        {title}
      </Link>
    </h2>
  );
}

const PostImage = ({ slug, image, description }) => {
  return (
    <a href={slug}>
      <GatsbyImage
        image={image}
        alt="alt tex"
        className="float-left h-24 mt-1 mr-5 mb-8"
      />
      <p className="mb-4 text-black text-justify">{description}</p>
    </a>
  );
}

const Post = ({ data }) => {
  const {
    title, date, category, description, attachments, lang,
  } = data.node.frontmatter;
  const { slug, categorySlug } = data.node.fields;
  const image = getImage(attachments[0]);

  return (
    <div className="max-w-post">
      <div className="mb-6">
        <PostDate date={date} lang={lang} />
        <PostCategory slug={categorySlug} text={category} />
        <PostTitle title={title} slug={slug} />
        <PostImage slug={slug} image={image} description={description} />
      </div>
      <div className="clear-both mb-4" />
    </div>
  );
}

export default Post;
