import React from 'react';
import Post from '../Post/Post';

const CategoryTemplateDetails = ({ pageContext, data, lang }) => {
  const { category } = pageContext;
  const posts = data.allMarkdownRemark.edges;
  const filteredPosts = posts.map((post) => (post.node.frontmatter.lang === lang ? (
    <Post data={post} key={post.node.fields.slug} />
  ) : null));

  return (
    <div className="content">
      <div className="my-10 mx-6">
        <h1 className="mt-2" id="anc">
          {category}
        </h1>
        {filteredPosts}
      </div>
    </div>
  );
}

export default CategoryTemplateDetails;
